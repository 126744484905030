@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';

#main {
    padding: 60px 0 0;
}

.form-control[readonly] {
    background-color: #fff;
}

.form-control[disabled] {
    background-color: #e9ecef;
}

.dropdown-toggle::after {
    color: rgba(255, 255, 255, 0.55);
}

.input-text-clear {
    position: relative;
}

.input-text-clear > svg {
    position: absolute;
    right: 15px;
    top: 0;
    padding: 10px 10px;
}

.row-hover {
    @extend .py-1;
    @extend .rounded-3;
    &:hover {
        @extend .bg-light;
    }
}

.input-required {
    position: relative;
    &::before {
        position: absolute;
        content: '*';
        left: 1px;
        color: red;
    }
}
